export default {
    frameworks: [
        {
          level:95,
          skill:'Pytorch'
        },
        {
          level:95,
          skill:'Tensorflow'
        },
        {
          level:90,
          skill:'Scikit-Learn'
        },
        {
          level:90,
          skill:'Pandas'
        },
        {
          level:95,
          skill:'Numpy'
        },
        {
          level:85,
          skill:'Spark'
        },
        {
          level:75,
          skill:'Kafka'
        },
        {
          level:95,
          skill:'React'
        },
        {
          level:85,
          skill:'Django'
        },
        {
          level:90,
          skill:'Flask'
        },
        {
          level:95,
          skill:'Node'
        },
        {
          level:78,
          skill:'.NET'
        },
        {
          level:85,
          skill:'Spring Boot'
        },
      ],
      programmingLanguages:[

      {
        level:95,
        skill:'Python'
      },
      {
        level:75,
        skill:'C++'
      },
      {
        level:70,
        skill:'Java'
      },
      {
        level:90,
        skill:'Javascript'
      },
      {
        level:85,
        skill:'SQL'
      },
      {
        level:75,
        skill:'C#'
      },
      {
        level:78,
        skill:'Bash'
      },
      {
        level:80,
        skill:'Scala'
      }
      
      

    ],
    webFrameworks: [{
        level:75,
        skill:'React'
      },
      {
        level:85,
        skill:'Django'
      },
      {
        level:50,
        skill:'Flask'
      },
      {
        level:40,
        skill:'Spring Boot'
      },
      {
        level:78,
        skill:'.NET'
      },
      {
        level:75,
        skill:'Node'
      },
      
    ],
      
}