import React from "react";
import {Card, CardHeader, CardBody, Image} from "@nextui-org/react";

export default function ProjectCard() {
  return (
    
    <Card className="py-4">
            <div style={{backgroundColor:'gray', width:'20.85vw'}}>
            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                <p className="text-tiny uppercase font-bold">Daily Mix</p>
                <small className="text-default-500">12 Tracks</small>
                <h4 className="font-bold text-large">Frontend Radio</h4>
            </CardHeader>
            <CardBody className="overflow-visible py-2">
                <Image
                alt="Card background"
                className="object-cover rounded-xl"
                src='https://staticg.sportskeeda.com/editor/2022/08/471f9-16609079272704-1920.jpg'
                width={270}
                />
            </CardBody>
        </div>
    </Card>
  );
}
